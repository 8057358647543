.vertical-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.horizontal-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

.vh-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
}

// Custom scss
.nav-logo {
  width: 140px;
  display: block;
  margin-left: auto;
  margin: 0;
}

.logo-title {
  // font-size: 16px;
  font-weight: 300;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  margin: 4px 0;
}

.chip {
  margin: 0 4px;
  padding: 4px 32px;
  border-radius: 25px;
  font-weight: 300;
  cursor: pointer;
  display: inline-block;
}

.tab-content {
  box-shadow: none;
}

.btn-action {
  padding: 4px 10px;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: transparent !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.css-yk16xz-control {
  background-color: #f4f7fa !important;
}

.input-currency {
  display: block;
  background: #f4f7fa;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#report-date {
  padding: 0.375rem 0.75rem;
}

.rangecontainer {
  display: none;
}

.task-status {
  color: #fff;
  padding: 3px 12px;
  border-radius: 25px;
}

.user-photo {
  height: 240px;
  width: 240px;
}

.MuiTreeItem-label {
  font-size: 16px !important;
  color: #706f6d;
  font-weight: 600;
  padding: 4px 0px;
}

.progress {
  background-color: #e6e8eb;
  height: 24px;
}
